import React from "react";

import illustration from "../../images/demander_une_demo.png";
import { Link } from "gatsby";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { LANGUAGE_TO_HOMEPAGE, lightBackgroundColor } from "../../constants";
import { translator } from "../../translations";

/**
 * Thanks component after a ContactForm has been filled. It is meant to be placed on its own page.
 */
export const DynamicContactFormThanks = ({
  locale,
  backgroundColor = lightBackgroundColor,
}) => {
  if (!locale) {
    return null;
  }

  const t = translator(locale);

  const homeUrl = LANGUAGE_TO_HOMEPAGE[locale] || "../";

  return (
    <div
      className={`${backgroundColor} flex flex-col w-full items-center pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8`}
    >
      <h1 className="text-3xl tracking-tight font-extrabold sm:text-4xl text-action-600">
        {t("contact-form-thanks.title")}
      </h1>
      <p className="mt-8 text-lg text-gray-500 text-center">
        {t("contact-form-thanks.formSubmitConfirm")}
      </p>
      <div className="w-64 sm:w-80 h-auto flex justify-center mt-10">
        <img src={illustration} alt="" />
      </div>
      <Link
        to={homeUrl}
        className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-action-700 bg-action-100 hover:bg-action-200 mt-10"
      >
        <span className="mr-1">
          <ArrowLeftIcon className="w-5" strokeWidth="0.5" />
        </span>
        {t("contact-form-thanks.backToHome")}
      </Link>
    </div>
  );
};
