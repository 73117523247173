import React from "react";
import {
  LANG_DE_DE,
  LANG_EN_CA,
  LANG_EN_US,
  LANG_FR_BE,
  LANG_FR_CA,
  lightBackgroundColor,
} from "../../constants";
import { translator } from "../../translations";

const LOCALE_TO_FORM_ENDPOINT = {
  [LANG_FR_BE]: "https://getform.io/f/bqonlnzb",
  [LANG_DE_DE]: "https://getform.io/f/agdymyob",
  [LANG_EN_US]: "https://getform.io/f/broyrvna",
  [LANG_FR_CA]: "https://getform.io/f/bejrzqda",
  [LANG_EN_CA]: "https://getform.io/f/agdpqxwb",
};

/**
 * ContactForm component for the dynamic pages. It takes a language as input and render a form with the correct linked getForms.
 * For the Component in French, see static component ContactForm.jsx
 */
export const DynamicContactForm = ({
  locale,
  backgroundColor = lightBackgroundColor,
}) => {
  const endpoint = LOCALE_TO_FORM_ENDPOINT[locale];

  const t = translator(locale);

  if (!endpoint) {
    return null;
  }

  return (
    <div
      className={`${backgroundColor} relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8`}
    >
      <div className="relative max-w-3xl mx-auto">
        <div className="text-center mb-8">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {t("contact-form.title")}
          </h2>
        </div>

        <form
          className="space-y-8 divide-y divide-gray-200"
          method="post"
          action={endpoint}
        >
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="pt-8">
              <div className="mb-12 text-base text-gray-500">
                <p>{t("contact-form.form-description")}</p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("contact-form.first-name")}&nbsp;*
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("contact-form.last-name")}&nbsp;*
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("contact-form.email")}&nbsp;*
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("contact-form.phone")}
                  </label>
                  <div className="mt-1">
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      autoComplete="tel"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="organisation"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("contact-form.organization")}
                  </label>
                  <div className="mt-1">
                    <input
                      id="organisation"
                      name="organisation"
                      type="text"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("contact-form.role")}
                  </label>
                  <div className="mt-1">
                    <input
                      id="role"
                      name="role"
                      type="text"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("contact-form.description")}
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      rows={3}
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      defaultValue={""}
                    />
                  </div>
                </div>

                {/* Honeypot input for bots spam */}
                <input
                  type="hidden"
                  name="_gotcha"
                  style={{ display: "none !important" }}
                />
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-action-600 hover:bg-action-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-action-500"
              >
                {t("contact-form.submit-text")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
