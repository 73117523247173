import * as React from "react";
import { Link } from "gatsby";
import { SectionContainer } from "../layout";
import { truncateBody } from "../../utils";
import { blogPath, lightBackgroundColor } from "../../constants";
import Image from "gatsby-plugin-sanity-image";

export const BlogListing = ({
  title = "Actualités",
  blog_posts = [],
  backgroundColor = lightBackgroundColor,
  locale = "fr-FR",
}) => {
  return (
    <SectionContainer className={backgroundColor}>
      <div className="text-center mb-16 max-w-xl lg:max-w-none mx-auto">
        <h2 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl">
          {title}
        </h2>
      </div>

      <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-7xl">
        {blog_posts.map(
          ({ id, mainImage, publishedAt, slug, title, body, description }) => {
            const excerpt = truncateBody(body, 220);
            return (
              <div
                className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                key={id}
              >
                <Link
                  to={blogPath({ locale: locale, slug: slug.current })}
                  className="h-full"
                >
                  <div className="flex-shrink-0">
                    <Image
                      {...mainImage}
                      className="h-48 w-full object-cover"
                      width={600}
                    />
                  </div>
                  <div className="flex-1 bg-white p-8 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm text-gray-500">
                        <time dateTime={publishedAt}>{publishedAt}</time>
                      </p>
                      <div className="block mt-3">
                        <h2 className="text-xl font-semibold text-gray-900">
                          {title}
                        </h2>
                        <p className="mt-3 text-base text-gray-500">
                          {description || excerpt}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          },
        )}
      </div>
    </SectionContainer>
  );
};
